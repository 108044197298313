import moment from 'moment';
import React from 'react';
import {
    List, Datagrid, TextField, TextInput, NumberInput, DateField, DateInput, BooleanInput,
    Edit, EditButton, Create, SimpleForm, regex, FormDataConsumer, Button, TopToolbar,
    sanitizeListRestProps, useNotify, CreateButton, ExportButton, downloadCSV, SelectInput,
} from 'react-admin';
import { downloadDiscountsReport } from '../requests/discount';
import { requiredValidate, percentValidator } from './validators';
import {DiscountGroup, maxCountRecordsToExport} from "./constants";
import {getFileName, toISTZone, toISTZoneWithoutChangeTime} from "../dataHelper";
import jsonExport from "jsonexport/dist";

const ReportButton = (props) => {
    const notify = useNotify();
    const [loading, setLoading] = React.useState(false);
    const handleClick = () => {
        if (!loading) {
            setLoading(true);
            downloadDiscountsReport().then(() => {
                notify('Comment approved');
            }).catch((e) => {
                notify('Error: comment not approved', 'warning')
            }).finally(() => {
                setLoading(false);
            });
        }
    };
    return <Button {...props} label="DOWNLOAD REPORT (XLSX)" onClick={handleClick} disabled={loading} />;
};

const ListActions = (props) => {
    return (
        <TopToolbar {...sanitizeListRestProps(props)}>
            <ReportButton {...props}/>
            <CreateButton {...props}/>
            <ExportButton {...props} maxResults={maxCountRecordsToExport}/>
        </TopToolbar>
    );
};

const exporter = (posts) => {
    const postsForExport = posts.map(post => {
        return {
            ...post,
            start: post.start ? toISTZoneWithoutChangeTime(post.start) : undefined,
            end: post.end ? toISTZoneWithoutChangeTime(post.end) : undefined,
            createdAt: toISTZone(post.createdAt),
            updatedAt: toISTZone(post.updatedAt),
        }
    });
    jsonExport(postsForExport, {}, (err, csv) => {
        downloadCSV(csv, getFileName("discounts", "csv"));
    });
};

export const DiscountList = (props) => (
    <List {...props} actions={<ListActions/>} builkActionButtons={<React.Fragment/>} exporter={exporter}>
        <Datagrid>
            <TextField label="№" source="index" sortable={false} />
            <TextField label="Code" source="code" sortable={false} />
            <TextField label="Percent" source="discountPercent" sortable={false} />
            <DateField
                label="Start date (IST)"
                source="start"
                sortable={false}
                options={{ year: 'numeric', month: 'long', day: 'numeric' }}
            />
            <DateField
                label="End date (IST)"
                source="end"
                sortable={false}
                options={{ year: 'numeric', month: 'long', day: 'numeric' }}
            />
            <EditButton label="EDIT"/>
        </Datagrid>
    </List>
);

const DiscountTitle = ({ record }) => {
    return <span>Discount {record ? record.code : ""}</span>;
};

export const DiscountCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="Code" source={"code"} validate={[requiredValidate, codeValidation]}/>
            <NumberInput label="Discount %"
                source={"discountPercent"}
                step={1}
                validate={percentValidator}
                defaultValue={null}
            />
            <BooleanInput label="Permanent" source="permanent" defaultValue={false}/>
            <FormDataConsumer>
                {({ formData, ...rest }) => !formData.permanent && (
                    <React.Fragment>
                        <DateInput
                            label="Start date"
                            source={"start"}
                            options={{locale: 'en'}}
                            validate={dateValidation}
                            parse={dateParse}
                            {...rest}
                        />
                        <DateInput
                            label="End date"
                            source={"end"}
                            options={{locale: 'en'}}
                            validate={dateValidation}
                            parse={endDateParse}
                            {...rest}
                        />
                    </React.Fragment>
                )}
            </FormDataConsumer>
            <SelectInput label="Fixed For Group" source="fixedForGroup" choices={DiscountGroup} />
        </SimpleForm>
    </Create>
);

export const DiscountEdit = (props) => (
    <Edit title={<DiscountTitle />} {...props}>
        <SimpleForm>
            <TextInput label="Code" source={"code"} validate={[requiredValidate, codeValidation]}/>
            <NumberInput label="Discount %"
                source={"discountPercent"}
                step={1}
                validate={percentValidator}
                defaultValue={null}
            />
            <BooleanInput label="Permanent" source="permanent" defaultValue={false}/>
            <FormDataConsumer>
                {({ formData, ...rest }) => !formData.permanent && (
                    <React.Fragment>
                        <DateInput label="Start date" source={"start"} options={{locale: 'en'}} validate={dateValidation} parse={dateParse} {...rest}/>
                        <DateInput label="End date" source={"end"} options={{locale: 'en'}} validate={dateValidation} parse={endDateParse} {...rest}/>
                    </React.Fragment>
                )}
            </FormDataConsumer>
            <SelectInput label="Fixed For Group" source="fixedForGroup" choices={DiscountGroup} />
        </SimpleForm>
    </Edit>
);

const codeValidation = regex(/^[a-z0-9]+$/i, 'Only digit and letter');

const dateValidation = (value, allValues) => {
    if (!value) {
        return 'Required';
    } else if (allValues.start && allValues.end 
        && moment(allValues.start).isValid() && moment(allValues.end).isValid()
        && moment(allValues.end).isBefore(moment(allValues.start))) {
        return `End date must be greater than start date`;
    }
};

const dateParse = (value) => {
    if (value) {
        return moment(value).local(true).toDate();
    }
};

const endDateParse = (value) => {
    if (value) {
        return moment(value).local(true).endOf("day").toDate();
    }
};
