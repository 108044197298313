import firebase from 'firebase';
import { padStart } from 'lodash';
import moment from 'moment';

export const uploadImage = async (file, path) => {
    const snapshot = await firebase.storage().ref(`images/${path}`).put(file);
    return snapshot.ref.getDownloadURL();
};

export const toIST = (date) => {
    const dateObject = date ? moment(date) : moment();
    return dateObject.utcOffset(330, false).utcOffset(moment().utcOffset(), true).format();
};

export const toISTZone = (date) => {
    if (date) {
        return moment(date).utcOffset(330, false).format();
    }
}

export const toISTZoneWithoutChangeTime = (date) => {
    // change time zone to +5:30, without update hours and minutes
    const dateObject = date ? moment(date) : moment();
    return dateObject.utcOffset(330, false).utcOffset(moment().utcOffset(), false).utcOffset(330, true).format();
};

export const ISTtoUTC = (date) => {
    const dateObject = date ? moment(date) : moment();
    return dateObject.utcOffset(330, true).toISOString();
};

export const getFileName = (name, format) => {
    const date = new Date();
    return `${name}_${date.toLocaleDateString()}_${padStart(date.getHours(), 2, "0")}-${padStart(date.getMinutes(), 2, "0")}-${padStart(date.getSeconds(), 2, "0")}.${format}`;
};

export const toDate = (date) => {
    if (date) {
        return moment(date).format("YYYY-MM-DD");
    }
};

export const endOfDay = (date) => {
    const dateObj = moment(date);
    if (date && dateObj.isValid()) {
        return dateObj.local(true).endOf("day").toDate();
    }
};
