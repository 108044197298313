import React from 'react';
import {
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    ImageField,
    EditButton,
    DeleteButton,
    TabbedForm,
    FormTab,
    ImageInput,
    SelectInput,
    NumberInput,
    TextInput,
    SelectArrayInput,
    BooleanInput,
    Show,
    SimpleShowLayout,
    ShowButton,
    TopToolbar,
    sanitizeListRestProps,
    ExportButton, downloadCSV, CreateButton,
} from 'react-admin';
import { amPmValidate, requiredValidate, passwordValidator, phoneValidator, hourValidator } from "./validators";
import { ClockChoises, maxCountRecordsToExport, servicesChoices } from './constants';
import { SearchFilter } from './components';
import {getFileName, toISTZone, toISTZoneWithoutChangeTime} from "../dataHelper";
import jsonExport from "jsonexport/dist";
import moment from "moment";

const ListActions = (props) => {
    return (
        <TopToolbar {...sanitizeListRestProps(props)}>
            <CreateButton {...props}/>
            <ExportButton {...props} maxResults={maxCountRecordsToExport} filter={props.filterValues}/>
        </TopToolbar>
    );
};

const exporter = (posts) => {
    const postsForExport = posts.map(post => {
        const isExistSchedules = post.schedules && Array.isArray(post.schedules) && post.schedules.length > 0;
        return {
            ...post,
            registrationDate: toISTZoneWithoutChangeTime(post.registrationDate),
            createdAt: toISTZone(post.createdAt),
            schedules: isExistSchedules && post.schedules.map((item) => (
                {
                    ...item,
                    time_end: toISTZone(moment(item.time_end).utcOffset(0, true)),
                    time_start: toISTZone(moment(item.time_start).utcOffset(0, true))
                }
            ))
        }
    });
    jsonExport(postsForExport, {}, (err, csv) => {
        downloadCSV(csv, getFileName("pharmacies", "csv"));
    });
};

export const PharmacyList = (props) => (
    <List
        {...props}
        bulkActionButtons={<React.Fragment/>}
        filters={<SearchFilter/>}
        actions={<ListActions/>}
        exporter={exporter}
    >
        <Datagrid>
            <TextField label="SI No." source="index" sortable={false} />
            <TextField label="Name"  source="pharmacyName" sortable={false} />
            <TextField label="Phone Number" source="phone" sortable={false} />
            <TextField label="CITY" source="city" sortable={false} />
            <TextField label="PIN" source="pin" sortable={false} />

            <ShowButton label="VIEW" />
            <EditButton label="EDIT"/>
            <DeleteButton label="DELETE" />
        </Datagrid>
    </List>
);

const PharmacyTitle = ({ record }) => {
    return <span>Pharmacy {record && record.name ? `"${record.name}"` : ''}</span>;
};

export const PharmacyShow = (props) => (
    <Show title={<PharmacyTitle />} {...props}>
        <SimpleShowLayout>
            <ImageField source="photo" title="photo" />
            <TextField label="№" source="index" />

            <TextField label="Name"  source="pharmacyName" />
            <TextField label="phone number" source="phone" />
            <TextField label="first name" source="firstname" />
            <TextField label="last name" source="lastname" />

            <TextField label="city" source="city" />
            <TextField label="PIN" source="pin" />
        </SimpleShowLayout>
    </Show>
);

export const PharmacyEdit = (props) => (
    <Edit title={<PharmacyTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Summary">
                <ImageInput source="photo_scr" label="Upload Photo" accept="image/*" >
                    <ImageField source="scr" title="preview" />
                </ImageInput>
                <ImageField source="photo" title="photo" />

                <TextInput label="Pharmacy Name" source="pharmacyName" validate={requiredValidate} />

                <TextInput label="First Name" source="firstname" />
                <TextInput label="Last Name" source="lastname" />

                <TextInput label="Phone Number" source="phone" validate={phoneValidator} disabled={true}/>

                <TextInput label="Address Line" source="address" />
                <TextInput label="City" source="city" />
                <TextInput label="PIN" source="pin" />
                <TextInput label="Contact Phone" source="contactPhone" validate={phoneValidator} />

                <SelectArrayInput source="services" optionText="name" optionValue="id" choices={servicesChoices} />
            </FormTab>

            <FormTab label="Hours">
                <NumberInput label="Start Time" source="schedules[0].hours_start" step={1} validate={hourValidator} />
                <SelectInput label="" source="schedules[0].clock_start" choices={ClockChoises} validate={amPmValidate} />
                <NumberInput label="End Time" source="schedules[0].hours_end" step={1} validate={hourValidator} />
                <SelectInput label="" source="schedules[0].clock_end" choices={ClockChoises} validate={amPmValidate} />
                <BooleanInput label="Monday" source="schedules[0].monday" />
                <BooleanInput label="Tuesday" source="schedules[0].tuesday" />
                <BooleanInput label="Wednesday" source="schedules[0].wednesday" />
                <BooleanInput label="Thursday" source="schedules[0].thursday" />
                <BooleanInput label="Friday" source="schedules[0].friday" />
                <BooleanInput label="Saturday" source="schedules[0].saturday" />
                <BooleanInput label="Sunday" source="schedules[0].sunday" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const PharmacyCreate = (props) => (
    <Create {...props}>
        <TabbedForm>
            <FormTab label="Summary">
                <ImageInput source="photo_scr" label="Upload Photo" accept="image/*" >
                    <ImageField source="scr" title="preview" />
                </ImageInput>
                <ImageField source="photo" title="photo" />

                <TextInput label="Pharmacy Name" source="pharmacyName" validate={requiredValidate} />
                <TextInput label="Password" source="password" validate={passwordValidator} />

                <TextInput label="First Name" source="firstname" />
                <TextInput label="Last Name" source="lastname" />

                <TextInput label="Phone Number" source="phone" validate={phoneValidator} />

                <TextInput label="Address Line" source="address" />
                <TextInput label="City" source="city" />
                <TextInput label="PIN" source="pin" />
                <TextInput label="Contact Phone" source="contactPhone" validate={phoneValidator} />

                <SelectArrayInput source="services" optionText="name" optionValue="id" choices={servicesChoices} />
            </FormTab>

            <FormTab label="Hours">
                <NumberInput label="Start Time" source="schedules[0].hours_start" step={1} validate={hourValidator} />
                <SelectInput label="" source="schedules[0].clock_start" choices={ClockChoises} validate={amPmValidate}/>
                <NumberInput label="End Time" source="schedules[0].hours_end" step={1} validate={hourValidator} />
                <SelectInput label="" source="schedules[0].clock_end" choices={ClockChoises} validate={amPmValidate} />
                <BooleanInput label="Monday" source="schedules[0].monday" />
                <BooleanInput label="Tuesday" source="schedules[0].tuesday" />
                <BooleanInput label="Wednesday" source="schedules[0].wednesday" />
                <BooleanInput label="Thursday" source="schedules[0].thursday" />
                <BooleanInput label="Friday" source="schedules[0].friday" />
                <BooleanInput label="Saturday" source="schedules[0].saturday" />
                <BooleanInput label="Sunday" source="schedules[0].sunday" />
            </FormTab>
        </TabbedForm>
    </Create>
);
