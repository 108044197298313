import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateInput,
    Filter,
    Edit,
    SimpleForm,
    EditButton,
    TextInput,
    Toolbar,
    DeleteButton
} from 'react-admin';
import { maxDate } from "./constants";
import { ToggleButton } from "./components";

const ListFilter = (props) => {
    return (
        <Filter {...props}>
            <DateInput
                label="Start date"
                source="from"
                options={{locale: 'en'}}
                alwaysOn
                inputProps={{max: maxDate}}
                style={{minWidth: 170}}
            />
            <DateInput
                label="End date"
                source="to"
                options={{locale: 'en'}}
                alwaysOn
                inputProps={{max: maxDate}}
                style={{minWidth: 170}}
            />
        </Filter>
    )
};

export const IPList = (props) => (
    <List {...props} filters={<ListFilter/>} bulkActionButtons={false}>
        <Datagrid>
            <TextField label="№" source="index" sortable={false} />
            <TextField label="IP" source="ip" sortable={false} />
            <TextField label="SMS count" source="smsCount" sortable={false} />
            <ToggleButton {...props} resource={"IP Blacklist"} source="blocked"/>
        </Datagrid>
    </List>
);

export const IPBlacklist = (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>
            <TextField label="№" source="index" sortable={false} />
            <TextField label="IP" source="ip" sortable={false} />
            <TextField label="Blocked at" source="createdAt" sortable={false} />
            <EditButton label="EDIT"/>
            <DeleteButton label="DELETE" />
        </Datagrid>
    </List>
);

const IPTitle = ({ record }) => {
    return <span>IP {record ? record.ip : ""}</span>;
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <DeleteButton/>
  </Toolbar>
);

export const IPBlacklistEdit = (props) => (
    <Edit title={<IPTitle />} {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <TextInput label="IP" source={"ip"} disabled={true}/>
        </SimpleForm>
    </Edit>
);
