import {first, isNil} from 'lodash';

export const requiredValidate = (value) => {
    if (!value && value !== 0) {
        return 'Required';
    }
};

export const phoneValidator = (phone) => {
    let error;
    if (!phone) {
        return 'The Phone Number is required';
    }
    if (isNaN(Number(phone))) {
        return 'Must be a number';
    }
    if (phone.length > 18) {
        return `The Phone lenght must contain 11 - 18 characters`;
    } else if (phone.length < 11) {
        return `The Phone lenght must contain 11 - 18 characters`;
    }
    return error;
};

export const hourValidator = (hour, allValues) => {
    let error;
    const {time_start, time_end} = first(allValues.schedules) || {};
    const isVisible = !isNil(time_start) || !isNil(time_end);
    if (isVisible && isNil(hour)) {
        return 'Required';
    }
    if (hour > 12) {
        return `Must be 12 or less`;
    } else if (hour < 0) {
        return `Must be at least 0`;
    }
    return error;
};

export const amPmValidate = (value, allValues) => {
    const {time_start, time_end} = first(allValues.schedules) || {};
    const isVisible = !isNil(time_start) || !isNil(time_end);
    if (isVisible && isNil(value)) {
        return 'Required';
    }
};

export const pricingValidator = (rate) => {
    let error;
    if (!isNil(rate) && rate < 0) {
        return `Must be at least 0`;
    }
    return error;
};

export const percentValidator = (value) => {
    let error;
    if (isNil(value)) {
        return error;
    } else if (value < 0) {
        return `Must be at least 0`;
    } else if (value > 100) {
        return `Must be 100 or less`;
    }
    return error;
};

export const passwordValidator = (value) => {
    if (!value) return 'Required';
    const regExpression = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=\\S+$).{8,}$');
    const isValid = regExpression.test(value.toLowerCase());
    return !isValid ? 'Password is invalid' : undefined;
};
