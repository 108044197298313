import React from 'react';
import { DateInput, Filter } from 'react-admin';
import {maxDate} from "../constants";

export const ExternalPatientFilter = (props) => {
    return (
        <Filter {...props}>
            <DateInput
                label="Start appt creation date"
                source="from"
                options={{locale: 'en'}}
                alwaysOn
                inputProps={{max: maxDate}}
                style={{minWidth: 170}}
            />
            <DateInput
                label="End appt creation date"
                source="to"
                options={{locale: 'en'}}
                alwaysOn
                inputProps={{max: maxDate}}
                style={{minWidth: 170}}
            />
        </Filter>
    )
};
