import React from 'react';
import {Admin, Resource} from 'react-admin';
import apiRestClient from './restClient';
import authClient from './authClient';
import {PatientList, PatientShow} from './resources/patients';
import {DoctorList, DoctorEdit} from './resources/doctors';
import {PharmacyList, PharmacyShow, PharmacyEdit, PharmacyCreate} from './resources/pharmacies';
import {CSVDownloadPage} from './forms';
import {DiscountList, DiscountCreate, DiscountEdit} from './resources/discounts';
import {AppointmentList, AppointmentShow, AppointmentCreate, AppointmentEdit} from './resources/appointments';
import {ExternalPatientList} from './resources/externalPatients';
import {IPList, IPBlacklist, IPBlacklistEdit} from './resources/ip-list';
import firebase from 'firebase';
import config from '../config';

const fb_config = {
    apiKey: config.apiKey,
    authDomain: config.authDomain,
    databaseURL: config.databaseURL,
    storageBucket: config.storageBucket,
};
firebase.initializeApp(fb_config);

const App = () => (
    <Admin
        title="SeekMed Admin Panel"
        authProvider={authClient}
        dataProvider={apiRestClient}
    >
        <Resource name="patients" show={PatientShow} list={PatientList}/>
        <Resource name="doctors" list={DoctorList} edit={DoctorEdit}/>
        <Resource
            name="pharmacies"
            show={PharmacyShow}
            create={PharmacyCreate}
            edit={PharmacyEdit}
            list={PharmacyList}
        />
        <Resource
            name="discounts"
            list={DiscountList}
            create={DiscountCreate}
            edit={DiscountEdit}
        />
        <Resource
            name="appointments"
            list={AppointmentList}
            create={AppointmentCreate}
            edit={AppointmentEdit}
            show={AppointmentShow}
        />
        <Resource name="external patients" list={ExternalPatientList} />
        <Resource name="Forms" list={CSVDownloadPage}/>
        <Resource name="IP" list={IPList}/>
        <Resource name="IP Blacklist" list={IPBlacklist} edit={IPBlacklistEdit}/>
    </Admin>
);

export default App;
