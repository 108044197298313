import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import config from '../config';

const LOGIN_URL = config.loginUrl;

const saveCredentials = ({ token, refreshToken }) => {
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
};

const clearCredentials = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
};

const checkCredentials = () => !!localStorage.getItem('token') && !!localStorage.getItem('refreshToken');

export default (type, params) => {

    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(LOGIN_URL, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(saveCredentials);

    } else if (type === AUTH_LOGOUT) {
        clearCredentials();
        return Promise.resolve();

    } else if (type === AUTH_ERROR) {
        const { status } = params;
        if (status === 401 || status === 403) {
            clearCredentials();
            return Promise.reject();
        }
        return Promise.resolve();

    } else if (type === AUTH_CHECK) {
        return checkCredentials() ? Promise.resolve() : Promise.reject({ redirectTo: '/login' });
    }

    return Promise.resolve();
}
