import React from 'react';
import {
    List, Datagrid, TextField, FunctionField, DateField,
    TopToolbar, sanitizeListRestProps, ExportButton, downloadCSV
} from 'react-admin';
import {
    getFullName,
    getRequestOfAppointment,
    getConsultationStatusOfAppointment, getPaymentStatusOfAppointment
} from './components';
import {getFileName, toISTZone, toISTZoneWithoutChangeTime} from "../dataHelper";
import jsonExport from "jsonexport/dist";
import {maxCountRecordsToExport} from "./constants";
import {ExternalPatientFilter} from "./components/externalPatientFilter";

const ListActions = (props) => {
    return (
        <TopToolbar {...sanitizeListRestProps(props)}>
            <ExportButton {...props} maxResults={maxCountRecordsToExport} filter={props.filterValues}/>
        </TopToolbar>
    );
};

const exporter = (posts) => {
    const postsForExport = posts.map(post => {
        return {
            patientName: getFullName(post.patient),
            role: post.patient.role,
            phoneNumber: post.patient.phone,
            dateOfBirth: post.patient.birthdate,
            placeOfBirth: `${post.patient.country}, ${post.patient.state}, ${post.patient.city}`,
            gender: post.patient.sex === 1 ? "Male" : "Female",
            email: post.patient.email,
            appointmentType: post.appointmentType,
            startDate: toISTZoneWithoutChangeTime(post.startDate),
            endDate: toISTZoneWithoutChangeTime(post.endDate),
            request: getRequestOfAppointment(post),
            consultationStatus: getConsultationStatusOfAppointment(post),
            paymentStatus: getPaymentStatusOfAppointment(post),
            doctorLastName: post.doctor.lastname,
            doctorFirstName: post.doctor.firstname,
            doctorSpecialization: post.doctor.speciality,
            doctorCity: post.doctor.city,
            doctorPhoneNumber: post.doctor.contactPhone,
        }
    });
    jsonExport(postsForExport, {}, (err, csv) => {
        downloadCSV(csv, getFileName("external patients", "csv"));
    });
};

export const ExternalPatientList = (props) => {
    return (
        <List
            {...props}
            actions={<ListActions/>}
            bulkActionButtons={<React.Fragment/>}
            filters={<ExternalPatientFilter/>}
            exporter={exporter}
        >
            <Datagrid>
                <TextField label="№" source="index" sortable={false} />
                <FunctionField label="Name" render={record => getFullName(record.patient)} sortable={false} />
                <TextField label="Phone number" source="patient.phone" sortable={false} />
                <TextField label="Email" source="patient.email" sortable={false} />
                <TextField label="Appointment type" source="appointmentType" sortable={false} />
                <DateField
                    label="Start date (IST)"
                    source="startDate"
                    sortable={false}
                    options={{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }}
                />
                <DateField
                    label="End date (IST)"
                    source="endDate"
                    sortable={false}
                    options={{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }}
                />
                <FunctionField label="Request" render={record => getRequestOfAppointment(record)} sortable={false} />
                <FunctionField label="Consultation status" render={record => getConsultationStatusOfAppointment(record)} sortable={false} />
                <FunctionField label="Payment status" render={record => getPaymentStatusOfAppointment(record)} sortable={false} />
            </Datagrid>
        </List>
    )
};
