import { get } from 'lodash';
import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    fetchUtils,
} from 'react-admin';
import config from '../../config';
import { ISTtoUTC, toIST } from '../dataHelper';

const API_URL = config.apiUrl;

const parseAppointment = (data) => ({
    ...data,
    startDate: toIST(data.startDate),
    endDate: toIST(data.endDate),
});

const formatAppointment = (data) => ({
    ...data,
    startDate: ISTtoUTC(data.startDate),
    endDate: ISTtoUTC(data.endDate),
});

export const convertRequest = async (type, params) => {
    let url = '';
    const {queryParameters} = fetchUtils;
    const options = {};
    const {page, perPage} = params.pagination || {};
    const {term} = params.filter || {};
    switch (type) {
        case GET_LIST:
            url = `${API_URL}/appointment?${queryParameters({page, perPage, term})}`;
            break;
        case GET_ONE:
            url = `${API_URL}/appointment/${params.id}`;
            break;
        case CREATE:
            url = `${API_URL}/appointment`;
            options.method = 'POST';
            options.body = JSON.stringify(formatAppointment(params.data));
            break;
        case UPDATE:
            url = `${API_URL}/appointment/${params.id}`;
            options.method = 'PUT';
            options.body = JSON.stringify(formatAppointment(params.data));
            break;
        case DELETE:
            url = `${API_URL}/appointment/${params.id}`;
            options.method = 'DELETE';
            break;
        default:
            break;
    }
    return { url, options };
};

export const convertResponse = (response, type, params) => {
    const { json } = response;
    switch (type) {
        case GET_ONE:
            const data = json;
            let usedDiscountCodes = "";
            (data.discountCodes || []).map((code) => {
                usedDiscountCodes += code ? `${code} ` : '';
            });
            return {
                data: {
                    ...parseAppointment(data),
                    id: data._id,
                    paymentLink: data.payment && data.payment.paymentId && (!data.payment.paymentType || data.payment.paymentType === "razerpay")
                        ? `${config.paymentLinkUrl}/${data.payment.paymentId}` : '',
                    usedDiscountCodes,
                    paymentExists: get(data, 'checklist.payment') === 'check',
                },
            };
        case CREATE:
        case UPDATE:
            return {
                data: {
                    ...parseAppointment(json),
                    id: json._id,
                    patientLogin: json.patient.login,
                    doctorLogin: json.doctor.login,
                },
            };
        case GET_LIST:
            const {data: list = [], total} = {...json};
            return {
                data: list.map((item, index) => ({
                    ...parseAppointment(item),
                    id: item._id,
                    paymentExists: get(item, 'checklist.payment') === 'check',
                    index: index + 1,
                })),
                total: total,
            };
        case DELETE:
            return {data: {id: params.id}};
        default:
            return {data: {}};
    }
};
