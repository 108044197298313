export const getFullName = (record) => record && `${record.firstname || ''} ${record.lastname || ''}`;

export const getRequestOfAppointment = (record) => {
    if (!!record.canceled) {
        return "Cancelled";
    } else if (!!record.rescheduled) {
        return "Rescheduled"
    }
    return "Requested";
}

export const getConsultationStatusOfAppointment = (record) => {
    return record.checklist && record.checklist.consultationPass === "check" ? "Conducted" : "Not Conducted"
}

export const getPaymentStatusOfAppointment = (record) => {
    return record.checklist && record.checklist.payment === "check" ? "Paid" : "Not Paid"
}
