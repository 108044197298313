import React, { Component } from 'react';
import { UPDATE } from 'react-admin';
import ToggleButtonComponent from 'react-toggle-button';
import restClient from '../../restClient';

export class ToggleButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.record ? this.props.record[this.props.source] : false
        };

        this.onToggle = this.onToggle.bind(this);
    }
    componentDidUpdate(prevProps) {
        const source = this.props.source;
        if (this.props.record[source] !== prevProps.record[source]) {
            this.setState({value: this.props.record[source]});
        }
    }
    componentDidMount() {
        this.setState({value: this.props.record ? this.props.record[this.props.source] : false});
    }
    onToggle = (value) => {
        this.props.record[this.props.source] = !value;
        this.setState({value: !value});

        restClient(UPDATE, this.props.resource, {id: this.props.record.id, data: this.props.record})
            .then(response => console.log(response));
    }
    render() {
        return (
            <ToggleButtonComponent
                value={ this.state.value | false }
                onToggle={(value) => {
                    this.onToggle(value);
                }}
            />
        );
    }
}
