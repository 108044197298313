import React from 'react';
import { Field } from 'react-final-form';
import { DateTimePicker } from '@material-ui/pickers';

const labelStyle = {
    marginRight: 10,
    color: "rgba(0, 0, 0, 0.3)",
    fontSize: 12,
    userSelect: "none",
    pointerEvents: "none",
};

const errorStyle = {
    marginLeft: 14,
    marginRight: 14,
    color: "#f44336",
    fontSize: 12,
    userSelect: "none",
    pointerEvents: "none",
};

export const DateTimeInput = (props) => (
    <Field
        {...props}
        name={props.source}
        validate={props.validate}
        render={({ input, meta }) => (
            <React.Fragment>
                <p style={labelStyle}>{props.label}</p>
                <DateTimePicker
                    value={input.value || new Date().toISOString()}
                    onChange={input.onChange}
                />
                {meta.error && <p style={errorStyle}>{meta.error}</p>}
            </React.Fragment>
        )}
    />
);
