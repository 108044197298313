import { fetchUtils } from 'react-admin';
import { get } from 'lodash';
import moment from 'moment';
import config from '../config';
import * as UserRequests from './requests/user';
import * as AppointmentRequests from './requests/appointment';
import * as DiscountRequests from './requests/discount';
import * as ExternalPatientsRequests from './requests/externalPatients';
import * as IPRequests from './requests/ip';
import * as IPBlackListRequests from './requests/ip-blacklist';

const API_URL = config.apiUrl;

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertRESTRequestToHTTP = async (type, resource, params) => {
    let url = '';
    const options = {};
    switch (resource) {
        case "doctors":
        case "pharmacies":
        case "patients":
            return UserRequests.convertRequest(type, params, resource);
        case "external patients":
            return ExternalPatientsRequests.convertRequest(type, params);
        case "discounts":
            return DiscountRequests.convertRequest(type, params);
        case "appointments":
            return AppointmentRequests.convertRequest(type, params);
        case "IP":
            return IPRequests.convertRequest(type, params);
        case "IP Blacklist":
            return IPBlackListRequests.convertRequest(type, params);
        default:
            return { url, options };
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} REST response
 */
const convertHTTPResponseToREST = (response, type, resource, params) => {
    const { json } = response;
    switch (resource) {
        case "doctors":
        case "pharmacies":
        case "patients":
            return UserRequests.convertResponse(response, type, resource, params);
        case "external patients":
            return ExternalPatientsRequests.convertResponse(response, type, params);
        case "discounts":
            return DiscountRequests.convertResponse(response, type, params);
        case "appointments":
            return AppointmentRequests.convertResponse(response, type, params);
        case "IP":
            return IPRequests.convertResponse(response, type, params);
        case "IP Blacklist":
            return IPBlackListRequests.convertResponse(response, type, params);
        default:
            return { data: json };
    }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a REST response
 */
export default async (type, resource, params) => {
    const {fetchJson} = fetchUtils;
    return convertRESTRequestToHTTP(type, resource, params)
        .then(({url, options}) => {
            const token = localStorage.getItem('token');
            if (!options.headers) {
                options.headers = new Headers({ Accept: 'application/json' });
            }
            options.headers.set('x-token', token);
            return fetchJson(url, options);
        })
        .then(response => convertHTTPResponseToREST(response, type, resource, params))
        .catch((error) => {
            const status = error.status;
            if (status === 401 || status === 403) {
                return Promise.reject(error);
            }
            return Promise.reject(get(error, "body.error.message", error))
        });
};
