import moment from "moment";
import React from "react";
import {
    SimpleFormIterator,
    ArrayInput,
    List,
    Datagrid,
    TextField,
    FunctionField,
    ImageField,
    Edit,
    EditButton,
    TabbedForm,
    FormTab,
    ImageInput,
    SelectInput,
    NumberInput,
    TextInput,
    BooleanInput,
    DateInput,
    DateField,
    TopToolbar,
    sanitizeListRestProps,
    ExportButton,
    downloadCSV,
} from "react-admin";
import {
    requiredValidate,
    phoneValidator,
    hourValidator,
    pricingValidator,
} from "./validators";
import {
    GenderChoises,
    ClockChoises,
    SpecialityChoises,
    DurationChoises,
    maxCountRecordsToExport,
} from "./constants";
import { getFullName, UserFilter, ToggleButton } from "./components";
import {
    getFileName,
    toDate,
    toISTZone,
    toISTZoneWithoutChangeTime,
} from "../dataHelper";
import jsonExport from "jsonexport/dist";

const ListActions = (props) => {
    return (
        <TopToolbar {...sanitizeListRestProps(props)}>
            <ExportButton
                {...props}
                maxResults={maxCountRecordsToExport}
                filter={props.filterValues}
            />
        </TopToolbar>
    );
};

const exporter = (posts) => {
    const postsForExport = posts.map((post) => {
        const isExistSchedules =
            post.schedules &&
            Array.isArray(post.schedules) &&
            post.schedules.length > 0;
        return {
            ...post,
            registrationDate: toISTZoneWithoutChangeTime(post.registrationDate),
            birthdate: toDate(post.birthdate),
            createdAt: toISTZone(post.createdAt),
            schedules:
                isExistSchedules &&
                post.schedules.map((item) => ({
                    ...item,
                    time_end: toISTZone(
                        moment(item.time_end).utcOffset(0, true)
                    ),
                    time_start: toISTZone(
                        moment(item.time_start).utcOffset(0, true)
                    ),
                })),
        };
    });
    jsonExport(postsForExport, {}, (err, csv) => {
        downloadCSV(csv, getFileName("doctors", "csv"));
    });
};

export const DoctorList = (props) => (
    <List
        filter={{ block: false }}
        {...props}
        bulkActionButtons={<React.Fragment />}
        filters={<UserFilter />}
        actions={<ListActions />}
        exporter={exporter}
    >
        <Datagrid>
            <TextField label="№" source="index" sortable={false} />
            <TextField source="phone" sortable={false} />
            <FunctionField
                label="Name"
                render={(record) => getFullName(record)}
                sortable={false}
            />
            <TextField source="role" sortable={false} />
            <TextField label="City" source="city" sortable={false} />
            <TextField label="PIN" source="pin" sortable={false} />
            <TextField label="Id" source="_id" sortable={false} />
            <DateField
                label="Registration date (IST)"
                source="registrationDate"
                sortable={false}
                options={{
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                }}
            />
            <ToggleButton
                {...props}
                label="Block Calls"
                resource={"doctors"}
                source="blockCalls"
            />
            <ToggleButton
                {...props}
                label="Top Doctor"
                resource={"doctors"}
                source="isTop"
            />
            <EditButton label="EDIT" />
            <ToggleButton {...props} resource={"doctors"} source="block" />
        </Datagrid>
    </List>
);

const DoctorTitle = ({ record }) => {
    return <span>Doctor {getFullName(record)}</span>;
};

export const DoctorEdit = (props) => (
    <Edit title={<DoctorTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Summary">
                <ImageInput
                    source="photo_scr"
                    label="Upload Photo"
                    accept="image/*"
                >
                    <ImageField source="scr" title="preview" />
                </ImageInput>
                <ImageField source="photo" title="photo" />

                <TextInput label="First Name" source="firstname" />
                <TextInput label="Last Name" source="lastname" />

                <TextInput
                    label="Phone Number"
                    source="phone"
                    validate={phoneValidator}
                    disabled={true}
                />

                <TextInput label="Address Line" source="address" />
                <TextInput label="City" source="city" />
                <TextInput label="Country" source="country" />
                <TextInput label="PIN" source="pin" />
                <TextInput
                    label="Contact Phone"
                    source="contactPhone"
                    validate={phoneValidator}
                />

                <TextInput label="degree" source="degree" />
                <TextInput label="hospital" source="hospital" />
                <TextInput
                    label="medicalRegistrationNumber"
                    source="medicalRegistrationNumber"
                />

                <SelectInput
                    label="Gender"
                    source="sex"
                    choices={GenderChoises}
                />
                <SelectInput
                    label="Speciality"
                    source="speciality"
                    choices={SpecialityChoises}
                />

                <DateInput
                    source="birthdate"
                    options={{ locale: "en" }}
                    parse={dateParse}
                />

                <NumberInput
                    label="Consultation Rate (INR)"
                    source="rate"
                    step={1}
                    validate={pricingValidator}
                    defaultValue={null}
                />
                <NumberInput
                    label="Low Consultation Rate (INR)"
                    source="lowRate"
                    step={1}
                    validate={pricingValidator}
                    defaultValue={null}
                />

                <SelectInput
                    label="Duration of appointment"
                    source="visitDuration"
                    choices={DurationChoises}
                />
            </FormTab>

            <FormTab label="Schedule">
                <ArrayInput source="schedules">
                    <SimpleFormIterator inline>
                        <NumberInput
                            label="Start Time"
                            source="hours_start"
                            step={1}
                            validate={[requiredValidate, hourValidator]}
                        />
                        <SelectInput
                            label=""
                            source="clock_start"
                            choices={ClockChoises}
                            validate={requiredValidate}
                        />
                        <NumberInput
                            label="End Time"
                            source="hours_end"
                            step={1}
                            validate={[requiredValidate, hourValidator]}
                        />
                        <SelectInput
                            label=""
                            source="clock_end"
                            choices={ClockChoises}
                            validate={requiredValidate}
                        />
                        <BooleanInput label="Monday" source="monday" />
                        <BooleanInput label="Tuesday" source="tuesday" />
                        <BooleanInput label="Wednesday" source="wednesday" />
                        <BooleanInput label="Thursday" source="thursday" />
                        <BooleanInput label="Friday" source="friday" />
                        <BooleanInput label="Saturday" source="saturday" />
                        <BooleanInput label="Sunday" source="sunday" />

                        <TextInput
                            label="Address Line"
                            source="address"
                            validate={requiredValidate}
                        />
                        <TextInput label="City" source="city" />
                        <TextInput label="State" source="state" />
                        <TextInput label="Country" source="country" />
                        <TextInput label="PIN" source="pin" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);

const dateParse = (value) => {
    if (value) {
        return moment(value).local(true).toDate();
    }
};
