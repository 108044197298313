import { GET_LIST } from 'react-admin';
import config from '../../config';
import {
    endOfDay,
    ISTtoUTC,
    toIST
} from '../dataHelper';

const API_URL = config.apiUrl;

const parseExternalUserData = (json) => {
    return {
        ...json,
        id: json._id,
        registrationDate: parseRegistrationDate(json),
        startDate: json.startDate ? toIST(json.startDate) : undefined,
        endDate: json.endDate ? toIST(json.endDate) : undefined,
    };
};

const parseRegistrationDate = (data) => {
    return data.createdAt ? toIST(data.createdAt) : undefined;
};

export const convertRequest = async (type, params) => {
    let url = '';
    const options = {};
    const {from, to} = params.filter || {};
    switch (type) {
        case GET_LIST:
            switch (type) {
                case GET_LIST:
                    url = `${API_URL}/appointment/statistic`;
                    options.method = 'POST';
                    options.body = JSON.stringify({
                        from: from ? ISTtoUTC(from) : undefined,
                        to: to ? ISTtoUTC(endOfDay(to)) : undefined
                    });
                    break;
                default:
                //
            }
            break;
        default:
            break;
    }
    return { url, options };
};

export const convertResponse = (response, type, params) => {
    const { json } = response;
    switch (type) {
        case GET_LIST:
            const {appointments = [], total} = {...json};
            return {
                data: appointments.map((item, index) => ({
                    ...parseExternalUserData(item),
                    index: index + 1,
                })),
                total: total,
            };
        default:
            //
    }
};
