import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    fetchUtils,
} from 'react-admin';
import { first, get } from 'lodash';
import moment from 'moment';
import stringHash from 'string-hash';
import config from '../../config';
import {
    endOfDay,
    ISTtoUTC,
    toIST,
    uploadImage
} from '../dataHelper';

const API_URL = config.apiUrl;

const getRole = (resource) => {
    let role;
    if (resource === "patients") {
        role = "patient";
    } else if (resource === "doctors") {
        role = "doctor";
    } else if (resource === "pharmacies") {
        role = "pharmacy";
    }
    return role;
};

const parsePharmacyDoctorData = (json) => {
    parseSchedules(json);
    parseRate(json);
    return {
        ...json,
        id: json._id,
        registrationDate: parseRegistrationDate(json),
    };
};

const parseUserData = (json) => {
    return {
        ...json,
        id: json._id,
        registrationDate: parseRegistrationDate(json),
    };
};

const parseSchedules = (data) => {
    const schedules = data.schedules;
    if (schedules && Array.isArray(schedules) && schedules.length > 0) {
        schedules.map(schedule => {
            if (schedule.time_start) {
                const time_start = moment.parseZone(schedule.time_start);
                if (time_start.isValid()) {
                    const hours_start = time_start.hours();
                    if (hours_start && hours_start > 12) {
                        schedule.hours_start = hours_start - 12;
                        schedule.clock_start = 12;
                    } else {
                        schedule.hours_start = hours_start;
                        schedule.clock_start = 0;
                    }
                }
            }
    
            if (schedule.time_end) {
                const time_end = moment.parseZone(schedule.time_end);
                if (time_end.isValid()) {
                    const hours_end = time_end.hours();
                    if (hours_end && hours_end > 12) {
                        schedule.hours_end = hours_end - 12;
                        schedule.clock_end = 12;
                    } else {
                        schedule.hours_end = hours_end;
                        schedule.clock_end = 0;
                    }
                }
            }
        }) 
    }
};

const parseRate = (data) => {
    const rates = data.pricingOptions;
    data.rate = get(rates, 'rate');
    data.lowRate = get(rates, 'lowRate');
};

const parseRegistrationDate = (data) => {
    return data.createdAt ? toIST(data.createdAt) : undefined;
};

const formatPharmaciesDoctorsData = async (type, resource, params) => {
    const photoFile = get(params.data.photo_scr, 'rawFile');
    if (photoFile) {
        const path = stringHash(params.data.phone);
        params.data.photo = await uploadImage(photoFile, path);
    }
    if (type === CREATE) {
        params.data.role = getRole(resource);
    }
    const schedules = params.data.schedules;
    if (schedules && Array.isArray(schedules) && schedules.length > 0) {
        schedules.map(schedule => {
            const hours_start = schedule.hours_start + schedule.clock_start;
            const hours_end = schedule.hours_end + schedule.clock_end;
            schedule.time_start = moment(hours_start*60*60*1000).utc().format("YYYY-MM-DDTHH:mm:ss");
            schedule.time_end = moment(hours_end*60*60*1000).utc().format("YYYY-MM-DDTHH:mm:ss");
        })
    }
    params.data.pricingOptions = {
        rate: params.data.rate,
        lowRate: params.data.lowRate,
    };
};

export const convertRequest = async (type, params, resource) => {
    let url = '';
    const {queryParameters} = fetchUtils;
    const options = {};
    const {page, perPage} = params.pagination || {};
    const {term, startRegistrationDate, endRegistrationDate, from, to} = params.filter || {};
    switch (type) {
        case CREATE:
            url = `${API_URL}/users`;
            options.method = 'POST';
            if (resource === "pharmacies" || resource === "doctors") {
                await formatPharmaciesDoctorsData(type, resource, params);
            }
            options.body = JSON.stringify(params.data);
        case GET_ONE:
            url = `${API_URL}/users/${params.id}`;
            break;
        case GET_LIST:
            const role = getRole(resource);
            url = `${API_URL}/users?${queryParameters({
                role: role,
                page,
                perPage,
                term,
                startRegistrationDate: startRegistrationDate ? ISTtoUTC(startRegistrationDate) : undefined,
                endRegistrationDate: endRegistrationDate ? ISTtoUTC(endOfDay(endRegistrationDate)) : undefined
            })}`;
            break;
        case UPDATE:
            url = `${API_URL}/users/${params.id}`;
            options.method = 'PUT';
            if (resource === "pharmacies" || resource === "doctors") {
                await formatPharmaciesDoctorsData(type, resource, params);
            }
            options.body = JSON.stringify(params.data);
            break;
        case DELETE:
            url = `${API_URL}/users/${params.id}`;
            options.method = 'DELETE';
            break;
        default:
            break;
    }
    return { url, options };
};

export const convertResponse = (response, type, resource, params) => {
    const { json } = response;
    if (type === GET_ONE && (resource === "pharmacies" || resource === "doctors")) {
        return {data: parsePharmacyDoctorData(json)};

    } else if (type === GET_ONE && (resource === "patients")) {
        return {data: parseUserData(json)};

    } else if (type === GET_LIST && (resource === "pharmacies" || resource === "doctors")) {
        const {data = [], total} = {...json};
        return {
            data: data.map((item, index) => ({
                ...parsePharmacyDoctorData(item),
                index: index + 1,
            })),
            total: total
        };

    } else if (type === GET_LIST) {
        const {data = [], total} = {...json};
        return {
            data: data.map((item, index) => ({
                ...parseUserData(item),
                index: index + 1,
            })),
            total: total,
        };

    } else if (type === UPDATE && (resource === "pharmacies" || resource === "doctors")) {
        return {data: parsePharmacyDoctorData(json)};
    } else if (type === CREATE && resource === "pharmacies") {
        return {data: parsePharmacyDoctorData(first(json))};
    }
    return {data: {}};
};
