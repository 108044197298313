import moment from 'moment';
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Show,
    ShowButton,
    FunctionField,
    BooleanField,
    SimpleShowLayout,
    UrlField,
    Create,
    Edit,
    TextInput,
    SelectInput,
    SimpleForm,
    EditButton,
    BooleanInput,
    TopToolbar,
    sanitizeListRestProps,
    CreateButton,
    ExportButton,
    downloadCSV
} from 'react-admin';
import { requiredValidate } from './validators';
import { AppointmentTypes, maxCountRecordsToExport } from './constants';
import { DateTimeInput, SearchFilter } from './components';
import { getFileName, toISTZoneWithoutChangeTime, toISTZone } from "../dataHelper";
import jsonExport from "jsonexport/dist";

const formatUserInfo = (user) => {
    if (user) {
        const fullName = [user.login, user.firstname, user.lastname].filter((e) => !!e).join(' ');
        return user.deletedAt ?
            <span style={{color: 'grey'}}>{fullName + ' (DELETED)'}</span> :
            fullName;
    }
};

const ListActions = (props) => {
    return (
        <TopToolbar {...sanitizeListRestProps(props)}>
            <CreateButton {...props}/>
            <ExportButton {...props} maxResults={maxCountRecordsToExport}/>
        </TopToolbar>
    );
};

const exporter = (posts) => {
    const postsForExport = posts.map(post => {
        return {
            ...post,
            startDate: toISTZoneWithoutChangeTime(post.startDate),
            endDate: toISTZoneWithoutChangeTime(post.endDate),
            payment: post.payment && post.payment.date ?
                {
                    ...post.payment,
                    date: toISTZone(post.payment.date)
                } : post.payment
        }
    });
    jsonExport(postsForExport, {}, (err, csv) => {
        downloadCSV(csv, getFileName("appointments", "csv"));
    });
};

export const AppointmentList = (props) => (
    <List
        {...props}
        bulkActionButtons={<React.Fragment/>}
        filters={<SearchFilter/>}
        actions={<ListActions/>}
        exporter={exporter}
    >
        <Datagrid>
            <FunctionField label="Doctor" render={record => record && formatUserInfo(record.userA)} sortable={false} />
            <FunctionField label="Patient" render={record => record && formatUserInfo(record.userB)} sortable={false} />
            <TextField label="Type" source="appointmentType" sortable={false} />
            <DateField
                label="Start date (IST)"
                source="startDate"
                sortable={false}
                options={{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }}
            />
            <DateField
                label="End date (IST)"
                source="endDate"
                sortable={false}
                options={{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }}
            />
            <BooleanField label="Payment" source="paymentExists" sortable={false} />
            <ShowButton label="View" />
            <EditButton label="EDIT"/>
        </Datagrid>
    </List>
);

const AppointmentTitle = ({ record }) => {
    return <span>Appointment {record && record.userA && record.userB ? `${record.userA.phone} ${record.userB.phone}` : ""}</span>;
};

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

export const AppointmentShow = (props) => (
    <Show title={<AppointmentTitle />} {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source={"id"}/>
            <FunctionField label="Doctor" render={record => record && formatUserInfo(record.userA)}/>
            <FunctionField label="Patient" render={record => record && formatUserInfo(record.userB)}/>
            <TextField label="Type" source="appointmentType" />
            <DateField label="Start date (IST)" source="startDate" options={dateOptions}/>
            <DateField label="End date (IST)" source="endDate" options={dateOptions}/>

            <TextField label="Payment system." source="payment.paymentType"/>
            <TextField label="Amount." source="payment.amount"/>
            <TextField label="Currency" source="payment.currency"/>
            <TextField label="Payment status" source="payment.status"/>
            <DateField label="Payment date" source="payment.date" options={{ year: 'numeric', month: 'long', day: 'numeric' }}/>
            <TextField label="Payment ID" source="payment.paymentId"/>
            <UrlField label="Payment info link" source={"paymentLink"}/>
            <TextField label="Used discount code" source="usedDiscountCodes" />
        </SimpleShowLayout>
    </Show>
);

const dateValidation = (value, allValues) => {
    if (!value) {
        return 'Required';
    } else if (allValues.startDate && allValues.endDate 
        && moment(allValues.startDate).isValid() && moment(allValues.endDate).isValid()
        && moment(allValues.endDate).isBefore(moment(allValues.startDate))) {
        return `End date must be greater than start date`;
    }
};

export const AppointmentCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="Patient Login" source={"patientLogin"} validate={requiredValidate}/>
            <TextInput label="Doctor Phone" source={"doctorLogin"} validate={requiredValidate}/>
            <SelectInput label="Type" source="appointmentType" choices={AppointmentTypes} validate={requiredValidate} />
            <DateTimeInput source="startDate" label="Start time (IST)" validate={dateValidation}/>
            <DateTimeInput source="endDate" label="End time (IST)" validate={dateValidation}/>
        </SimpleForm>
    </Create>
);

export const AppointmentEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="ID" source={"id"} disabled={true}/>
            <FunctionField label="Doctor" render={record => record && formatUserInfo(record.userA)} />
            <FunctionField label="Patient" render={record => record && formatUserInfo(record.userB)} />
            <SelectInput label="Type" source="appointmentType" choices={AppointmentTypes} validate={requiredValidate} />
            <DateTimeInput source="startDate" label="Start time (IST)" validate={dateValidation}/>
            <DateTimeInput source="endDate" label="End time (IST)" validate={dateValidation}/>
            <BooleanInput label="Payment" source="paymentExists"/>
        </SimpleForm>
    </Edit>
);



