import React from 'react';
import { DateInput, TextInput, Filter } from 'react-admin';
import {maxDate} from "../constants";

export const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="term" alwaysOn />
        <DateInput
            label="Start Registration Date"
            source="startRegistrationDate"
            options={{locale: 'en'}}
            alwaysOn
            inputProps={{max: maxDate}}
            style={{minWidth: 170}}
        />
        <DateInput
            label="End Registration Date"
            source="endRegistrationDate"
            options={{locale: 'en'}}
            alwaysOn
            inputProps={{max: maxDate}}
            style={{minWidth: 170}}
        />
    </Filter>
);
