import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    fetchUtils,
} from 'react-admin';
import config from '../../config';
import { endOfDay,ISTtoUTC } from '../dataHelper';

const API_URL = config.apiUrl;

export const convertRequest = async (type, params) => {
    let url = '';
    const {queryParameters} = fetchUtils;
    const options = {};
    const {page, perPage} = params.pagination || {};
    const {from, to} = params.filter || {};
    switch (type) {
        case GET_LIST:
            url = `${API_URL}/ip?${queryParameters({
                page,
                perPage,
                from: from ? ISTtoUTC(from) : undefined,
                to: to ? ISTtoUTC(endOfDay(to)) : undefined
            })}`;
            break;
        case UPDATE:
            if (params.data.blocked && !params.data.blacklistId) {
                url = `${API_URL}/ip-blacklist`;
                options.method = 'POST';
                options.body = JSON.stringify({ip: params.data.ip});
            } else if (!params.data.blocked && params.data.blacklistId) {
                url = `${API_URL}/ip-blacklist/${params.data.blacklistId}`;
                options.method = 'DELETE';
            }
            break;
        default:
            break;
    }
    return { url, options };
};

export const convertResponse = (response, type, params) => {
    const { json } = response;
    switch (type) {
        case GET_ONE:
        case CREATE:
        case UPDATE:
        case DELETE:
            return {data: {...json, id: json._id}};
        case GET_LIST:
            const {data = [], total} = {...json};
            return {
                data: data.map((item, index) => (
                    {
                        ...item,
                        id: item.ip,
                        index: index + 1,
                        blocked: !!item.blacklistId
                    }
                )),
                total: total,
            };
        default:
            return { data: json };
    }
};
