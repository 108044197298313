import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CREATE, showNotification } from 'react-admin';
import FileReaderInput from 'react-file-reader-input';
import Babyparse from 'babyparse';
import { get } from 'lodash';
import Moment from 'moment';
import restClient from './restClient';

class CSVDownloadPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            doctorsFilePath: '',
            pharmaciesFilePath: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.doctorsHandleChange = this.doctorsHandleChange.bind(this);
        this.pharmaciesHandleChange = this.pharmaciesHandleChange.bind(this);
        this.sendData = this.sendData.bind(this);
    }

    sendData = () => {
        if (!this.state.data || this.state.data.length < 1) return;
        restClient(CREATE, "forms", {data: this.state.data})
            .catch((err) => this.props.showNotification(get(err, 'message', 'Server error'), 'warning'));
    }

    handleChange = (e, results) => {
        results.forEach(result => {
            const [e, file] = result;
            let parse_data = Babyparse.parse(e.target.result, {header: true});

            let data = [];
            parse_data.data.map((item) => {
                if (!item.phone) return;
                if (item.services) {
                    item.services = typeof item.services === "string" ? item.services.split(",") : item.services;
                }
                if (item.birthdate) {
                    item.birthdate = Moment(item.birthdate).format();
                }
                data.push(item);
            });

            this.setState({data: data});
        });
    }

    doctorsHandleChange = (e, results) => {
        const [event, file] = results[0];
        this.setState({doctorsFilePath: file.name}, () => {
            this.handleChange(e, results);
        });
    }

    pharmaciesHandleChange = (e, results) => {
        const [event, file] = results[0];
        this.setState({pharmaciesFilePath: file.name}, () => {
            this.handleChange(e, results);
        });
    }

    render() {
        return (
            <form>
                <div style={{padding: 15}}>
                    <label htmlFor="doctors-input">
                        <h2>Upload Doctor Details</h2>
                    </label>

                    <input type="text" value={this.state.doctorsFilePath} style={{margin: 10, float: 'left'}} />

                    <FileReaderInput as="text" id="doctors-input" onChange={this.doctorsHandleChange} >
                        <button style={{margin: 10, float: 'left'}} >Browse...</button>
                    </FileReaderInput>

                    <button onClick={this.sendData} style={{margin: 10}} >Submit</button>
                </div>

                <div style={{padding: 15}}>
                    <label htmlFor="doctors-input">
                        <h2>Upload Pharmacy Details</h2>
                    </label>

                    <input type="text" value={this.state.pharmaciesFilePath} style={{margin: 10, float: 'left'}} />

                    <FileReaderInput as="text" id="pharmacies-input" onChange={this.pharmaciesHandleChange} >
                        <button style={{margin: 10, float: 'left'}} >Browse...</button>
                    </FileReaderInput>

                    <button onClick={this.sendData} style={{margin: 10}} >Submit</button>
                </div>
            </form>
        );
    }
}

const form = connect(null, {showNotification})(CSVDownloadPage);
export {form as CSVDownloadPage};
