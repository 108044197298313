export const GenderChoises = [
    { id: 1, name: 'Male' },
    { id: 0, name: 'Female' },
];

export const ClockChoises = [
    { id: 0, name: 'am' },
    { id: 12, name: 'pm' },
];

export const SpecialityChoises = [
    { id: 'child', name: 'Pediatrician (Child Doctor)' },
    { id: 'women', name: 'Gynecologists (Women Doctors)' },
    { id: 'bone', name: 'Orthopedic (Bone and Joint Specialist)' },
    { id: 'brain', name: 'Neurologist' },
    { id: 'neuro', name: 'Neuro Surgeon' },
    { id: 'cancer', name: 'Oncologist (Cancer Specialist)' },
    { id: 'ent', name: 'Ear, Nose, Throat Specialist' },
    { id: 'digestion', name: 'Gastroenterologist (Digestive and Liver Specialist)' },
    { id: 'kidney', name: 'Nephrologist (Kidney Specialist)' },
    { id: 'dentist', name: 'Dentist' },
    { id: 'eye', name: 'Ophthalmologist (Eye Specialist)' },
    { id: 'urologist', name: 'Urologist' },
    { id: 'gi', name: 'GI Surgeon' },
    { id: 'plastic', name: 'Plastic Surgeon' },
    { id: 'respiratory', name: 'Pulmonologist (Respiratory Specialist)' },
    { id: 'internal', name: 'Internal Medicine (General Physician)' },
    { id: 'endocrinologist', name: 'Endocrinologist' },
    { id: 'skin', name: 'Dermatologist (Skin Specialist)' },
    { id: 'radiologist', name: 'Radiologist' },
    { id: 'cardiologist', name: 'Cardiologist' },
    { id: 'cardiacSurgeon', name: 'Cardiac Surgeon' },
    { id: 'psychiatrist', name: 'Psychiatrist' },
    { id: 'transplantSurgeonCardiac', name: 'Transplant Surgeon Cardiac' },
    { id: 'transplantSurgeonLiver', name: 'Transplant Surgeon Liver' },
    { id: 'transplantSurgeonKidney', name: 'Transplant Surgeon Kidney' },
    { id: 'newbornInfantSpecialist', name: 'Newborn Infant Specialist (Neonatologist)' },
    { id: 'blood', name: 'Haematologist (Blood Specialist)' },
    { id: 'microbiology_labMedicine', name: 'Microbiology & Lab Medicine' },
    { id: 'nutrition_specialist', name: 'Nutrition Specialist' },
    { id: 'colorectal_surgeon', name: 'Colorectal Surgeon' },
    { id: 'physiotherapist', name: 'Physiotherapist' },
    { id: 'rheumatologist', name: 'Rheumatologist' },
    { id: 'radiology', name: 'Vascular Interventional Radiology' },
    { id: 'obesity_bariatric_surgery', name: 'Obesity and Bariatric Surgery' },
    { id: 'pediatric_surgeon', name: 'Pediatric Surgeon' },
    { id: 'oral_and_maxillofacial_surgeons', name: 'Oral and Maxillofacial Surgeons' },
];

export const servicesChoices = [
    { id: 'vaccination', name: 'Vaccination' },
    { id: 'bloodWork', name: 'Blood Work' },
    { id: 'heartRateMonitoring', name: 'BP/Heart Rate Monitoring' },
    { id: 'prescriptions', name: 'Prescriptions' },
    { id: 'minorTreatments', name: 'Minor Treatments' },
];

export const AppointmentTypes = [
    { id: 'FIRST_VISIT', name: 'First visit' },
    { id: 'SECOND_OPINION', name: 'Second opinion' },
    { id: 'FOLLOW_UP', name: 'Follow up' },
];

export const DurationChoises = [
    { id: 15, name: '15 mins' },
    { id: 30, name: '30 mins' },
    { id: 45, name: '45 mins' },
    { id: 60, name: '60 mins' },
];

export const maxCountRecordsToExport = 20000;
export const maxDate = "9999-12-31"; // max letter count - 4 symbols for year in input type=date

export const DiscountGroup = [
    { id: 'max_bupa', name: 'Niva Bupa' },
];
