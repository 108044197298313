import React from 'react';
import {
    List, Datagrid, TextField, FunctionField, BooleanField, DateField,
    ImageField, Show, SimpleShowLayout, ShowButton, TopToolbar, sanitizeListRestProps,
    ExportButton, downloadCSV, DeleteButton
} from 'react-admin';
import { getFullName, UserFilter, ToggleButton } from './components';
import { toISTZoneWithoutChangeTime, getFileName, toDate, toISTZone } from "../dataHelper";
import jsonExport from "jsonexport/dist";
import {maxCountRecordsToExport} from "./constants";

const ListActions = (props) => {
    return (
        <TopToolbar {...sanitizeListRestProps(props)}>
            <ExportButton {...props} maxResults={maxCountRecordsToExport} filter={props.filterValues}/>
        </TopToolbar>
    );
};

const exporter = (posts) => {
    const postsForExport = posts.map(post => {
        return {
            ...post,
            createdAt: toISTZone(post.createdAt),
            registrationDate: toISTZoneWithoutChangeTime(post.registrationDate),
            birthdate: toDate(post.birthdate),
        }
    });
    jsonExport(postsForExport, {}, (err, csv) => {
        downloadCSV(csv, getFileName("patients", "csv"));
    });
};

export const PatientList = (props) => {
    return (
        <List
            {...props}
            actions={<ListActions/>}
            bulkActionButtons={<React.Fragment/>}
            filters={<UserFilter/>}
            exporter={exporter}
        >
            <Datagrid>
                <TextField label="№" source="index" sortable={false} />
                <FunctionField label="Name" render={record => getFullName(record)} sortable={false} />
                <TextField label="Login (Member Id)" source="login" sortable={false} />
                <TextField label="City" source="city" sortable={false} />
                <TextField label="PIN" source="pin" sortable={false} />
                <TextField label="Email" source="email" sortable={false} />
                <TextField label="Company" source="externalSystem" sortable={false} />
                <TextField label="Policy Number" source="policyNumber" sortable={false} />
                <DateField
                    label="Registration date (IST)"
                    source="registrationDate"
                    sortable={false}
                    options={{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }}
                />
                <ShowButton label="View" />
                <ToggleButton {...props} resource={"patients"} source="block" />
                <DeleteButton label="DELETE" />
            </Datagrid>
        </List>
    )
};

const PatientTitle = ({ record }) => {
    return <span>Patient {getFullName(record)}</span>;
};

export const PatientShow = (props) => {
    return (
        <Show title={<PatientTitle />} {...props}>
            <SimpleShowLayout>
                <ImageField source="photo" title="Photo" />

                <TextField label="Login (Member Id)" source="login" />
                <TextField label="First Name" source="firstname" />
                <TextField label="Last Name" source="lastname" />
                <TextField label="Mobile Number" source="phone" />

                <TextField label="Company" source="externalSystem" />
                <TextField label="Policy Number" source="policyNumber" />

                <TextField label="Country" source="country" />
                <TextField label="City" source="city" />
                <TextField label="Address" source="address" />
                <TextField label="State" source="state" />
                <TextField label="Street" source="street" />
                <TextField label="PIN" source="pin" />

                <FunctionField label="Gender" source="sex" render={record => (record.sex === 1) ? "Male" : (record.sex === 0) ? "Female" : ""} />
                <DateField label="Birth Date" source="birthdate" options={{ year: 'numeric', month: 'long', day: 'numeric' }} />
                <TextField label="Email" source="email" />

                <TextField label="Preferred Pharmacy" source="preferredPharmacy" />
                <TextField label="Emergency Contact" source="emergencyContact" />
                <TextField label="Emergency Phone" source="emergencyPhone" />
                <TextField label="Primary Doctor" source="primaryDoctor" />
                <TextField label="Medical Condition" source="medicalCondition" />
                <TextField label="Allergies" source="allergies" />
                <TextField label="Blood Group" source="bloodGroup" />
                <BooleanField label="Blood Donor" source="bloodDonor" />
            </SimpleShowLayout>
        </Show>
    )
};
