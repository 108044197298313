import moment from 'moment';
import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    fetchUtils,
} from 'react-admin';
import config from '../../config';
import { toIST } from '../dataHelper';

const API_URL = config.apiUrl;

export const downloadDiscountsReport = async () => {
    const start = moment().subtract(3, 'month');
    const end = moment();
    return fetch(`${API_URL}/discount/report?start=${start.unix()}&end=${end.unix()}`, {
        headers: {'x-token': localStorage.getItem('token')}
    })
        .then((res) => res.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `discount report ${start.format('DD-MM-YYYY')}_${end.format('DD-MM-YYYY')}.xlsx`;
            document.body.appendChild(a);
            a.click();    
            a.remove();
        });
};

export const convertRequest = async (type, params) => {
    let url = '';
    const {queryParameters} = fetchUtils;
    const options = {};
    const {page, perPage} = params.pagination || {};
    switch (type) {
        case GET_LIST:
            url = `${API_URL}/booking/discount?${queryParameters({page, perPage})}`;
            break;
        case GET_ONE:
            url = `${API_URL}/booking/discount/${params.id}`;
            break;
        case CREATE:
            url = `${API_URL}/booking/discount`;
            options.method = 'POST';
            options.body = JSON.stringify(params.data);
            break;
        case UPDATE:
            url = `${API_URL}/booking/discount/${params.id}`;
            options.method = 'PUT';
            options.body = JSON.stringify(params.data);
            break;
        case DELETE:
            url = `${API_URL}/booking/discount/${params.id}`;
            options.method = 'DELETE';
            break;
        default:
            break;
    }
    return { url, options };
};

export const convertResponse = (response, type, params) => {
    const { json } = response;
    switch (type) {
        case GET_ONE:
        case CREATE:
        case UPDATE:
            return {data: {...json, id: json._id}};
        case DELETE:
            return {data: {id: params.id}};
        case GET_LIST:
            const {data = [], total} = {...json};
            return {
                data: data.map((item, index) => ({
                    ...item,
                    id: item._id,
                    index: index + 1,
                    start: item.start ? toIST(item.start) : undefined,
                    end: item.end ? toIST(item.end) : undefined,
                })),
                total: total,
            };
        default:
            return { data: json };
    }
};
