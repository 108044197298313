import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    fetchUtils,
} from 'react-admin';
import config from '../../config';

const API_URL = config.apiUrl;

export const convertRequest = async (type, params) => {
    let url = '';
    const {queryParameters} = fetchUtils;
    const options = {};
    const {page, perPage} = params.pagination || {};
    switch (type) {
        case GET_LIST:
            url = `${API_URL}/ip-blacklist?${queryParameters({page, perPage})}`;
            break;
        case GET_ONE:
            url = `${API_URL}/ip-blacklist/${params.id}`;
            break;
        case DELETE:
            url = `${API_URL}/ip-blacklist/${params.id}`;
            options.method = 'DELETE';
            break;
        default:
            break;
    }
    return { url, options };
};

export const convertResponse = (response, type, params) => {
    const { json } = response;
    switch (type) {
        case GET_ONE:
        case CREATE:
        case UPDATE:
        case DELETE:
            return {data: {...json, id: json._id}};
        case GET_LIST:
            const {data = [], total} = {...json};
            return {
                data: data.map((item, index) => (
                    {
                        ...item,
                        id: item._id,
                        index: index + 1
                    }
                )),
                total: total,
            };
        default:
            return { data: json };
    }
};
